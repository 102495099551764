exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-newsletter-feed-newsletter-title-js": () => import("./../../../src/pages/newsletter/{FeedNewsletter.title}.js" /* webpackChunkName: "component---src-pages-newsletter-feed-newsletter-title-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-founderfit-page-js": () => import("./../../../src/templates/founderfit-page.js" /* webpackChunkName: "component---src-templates-founderfit-page-js" */),
  "component---src-templates-how-it-works-page-js": () => import("./../../../src/templates/how-it-works-page.js" /* webpackChunkName: "component---src-templates-how-it-works-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-investors-page-js": () => import("./../../../src/templates/investors-page.js" /* webpackChunkName: "component---src-templates-investors-page-js" */),
  "component---src-templates-login-page-js": () => import("./../../../src/templates/login-page.js" /* webpackChunkName: "component---src-templates-login-page-js" */),
  "component---src-templates-newsletter-page-js": () => import("./../../../src/templates/newsletter-page.js" /* webpackChunkName: "component---src-templates-newsletter-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-signup-page-js": () => import("./../../../src/templates/signup-page.js" /* webpackChunkName: "component---src-templates-signup-page-js" */),
  "component---src-templates-terms-of-service-page-js": () => import("./../../../src/templates/terms-of-service-page.js" /* webpackChunkName: "component---src-templates-terms-of-service-page-js" */)
}

